<template>
  <div class="chat">
    <!--
    <div v-if="!isOpen" class="chat-activation" @click="setOpen(true)">
      <i class="fas fa-comments"></i>
    </div>
    -->
    <div v-if="isOpen" class="chat-container">
      <div class="chat-header">
        <div class="chat-avatar">
          <img
            src="https://images-na.ssl-images-amazon.com/images/M/MV5BODFjZTkwMjItYzRhMS00OWYxLWI3YTUtNWIzOWQ4Yjg4NGZiXkEyXkFqcGdeQXVyMTQ0ODAxNzE@._V1_UX172_CR0,0,172,256_AL_.jpg"
            alt=""
          />
        </div>
        <div class="chat-info">
          <span class="chat-title">Juliana da Silva</span>
          <span class="chat-subtitle">Agendado: 10/07/2020 - 16:45</span>
        </div>
        <div @click="setOpen(false)" class="close-chat">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="header-divider"></div>
      <div class="chat-messages">
        <div class="chat-message">
          <div class="message-receive">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
        </div>
        <div class="chat-message">
          <div class="message-send">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
        </div>
        <div class="chat-message">
          <div class="message-receive">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
        </div>
        <div class="chat-message">
          <div class="message-send">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
        </div>
        <div class="chat-message">
          <div class="message-receive">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
        </div>
        <div class="chat-message">
          <div class="message-send">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
        </div>
        <div class="chat-message">
          <div class="message-receive">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
        </div>
        <div class="chat-message">
          <div class="message-send">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit.
          </div>
        </div>
      </div>
      <div class="chat-send">
        <input type="text" />
        <div class="message-icon" @click="sendMessage">
          <i class="fas fa-paper-plane"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import firebase from 'firebase'
var config = {
  apiKey: 'AIzaSyBiw1bYkc4PKnp0LkZQ9EW913Zt_qM0cFk',
  authDomain: 'test-project-e6287.firebaseapp.com',
  databaseURL: 'https://test-project-e6287.firebaseio.com',
  projectId: 'test-project-e6287',
  storageBucket: 'test-project-e6287.appspot.com',
  messagingSenderId: '993064383150'
}

firebase.initializeApp(config)
export default {
  computed: {
    ...mapGetters('chat', ['isOpen'])
  },
  methods: {
    ...mapActions('chat', ['setOpen']),
    async sendMessage (e) {
      e.preventDefault()
      if (e.target.value) {
        const message = {
          username: this.username,
          text: e.target.value
        }
        firebase.database().ref('messages').push(message)
        e.target.value = ''
      }
    }
  },
  mounted () {
    var vm = this
    const itemsRef = firebase.database().ref('messages')
    itemsRef.on('value', snapshot => {
      var data = snapshot.val()
      var messages = []
      Object.keys(data).forEach(key => {
        messages.push({
          id: key,
          username: data[key].username,
          text: data[key].text
        })
      })
      vm.messages = messages
    })
  }
}
</script>

<style scoped lang="scss">
.chat {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
}

.chat-container {
  width: 380px;
  height: 540px;
  background: #fff;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  overflow: hidden;
}

.chat-activation {
  width: 60px;
  height: 60px;
  color: #f6f6f6;
  background-color: #47be71;
  border-radius: 100%;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
}

.chat-activation:hover {
  background-color: darken(#47be71, 5%);
}

.chat-header {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
}

.chat-avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 15px;
  object-fit: cover;
}

.chat-info {
  width: calc(100% - 110px);
  display: flex;
  flex-direction: column;
}

.chat-title {
  color: #5c575c;
  font-size: 18px;
  font-weight: bold;
}

.chat-subtitle {
  color: #939393;
  font-size: 12px;
}

.close-chat {
  padding: 1rem;
  border-radius: 100%;
  font-size: 30px;
  font-weight: 400;
  cursor: pointer;
}

.chat-messages {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 360px;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.chat-message {
  width: 100%;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.chat-message:last-child {
  margin-bottom: 1.5rem;
}

.chat-message div {
  width: 80%;
  font-size: 12px;
  padding: 10px;
}

.message-receive {
  background: #f2f2f2;
  color: #5c575c;
  border-radius: 15px 15px 15px 0;
}

.message-send {
  background: #ecf9f0;
  color: #405343;
  border-radius: 15px 15px 0 15px;
  margin-left: auto;
}

.chat-send {
  border: 1px solid #eaeaea;
  width: calc(100% - 3rem);
  margin-left: auto;
  margin-right: auto;
  border-radius: 3rem;
  display: flex;
  height: 50px;
  justify-content: flex-end;
}

.chat-send input {
  width: calc(100% - 5rem);
  padding: 0.75rem 1rem 0.75rem 0;
}

.chat-send .message-icon {
  width: 4rem;
  color: #05b446;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
</style>
